import { getIsMaestroStandalone } from './shellHelpers';

/**
 * Example: /foo/bar -> inMaestro ? /foo/bar : /maestro/foo/bar
 * @param pathname
 */
export const getMaestroPath = (pathname: string) => {
  if (pathname.startsWith(`/maestro`)) {
    throw new Error(`Pathname must not start with the "/maestro" prefix.`);
  }
  if (!pathname.startsWith('/')) {
    throw new Error(`Pathname must start with slash "/".`);
  }
  if (getIsMaestroStandalone()) {
    return pathname;
  }
  return `/maestro${pathname}`;
};
