import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const ACCESS_REQUEST_PARAM_KEY = 'isAccessRequest';

export const useAccessRequestQueryParam = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const isRequestingAccess = useMemo(
    () => searchParams.get('isAccessRequest') === 'true',
    [searchParams]
  );

  const removeAccessRequestQueryParam = useCallback(() => {
    setSearchParams(
      (queryParams) => {
        queryParams.delete(ACCESS_REQUEST_PARAM_KEY);
        return queryParams;
      },
      {
        replace: true,
      }
    );
  }, [setSearchParams]);

  return {
    isRequestingAccess,
    removeAccessRequestQueryParam,
  };
};
