import excPage from '@adobe/exc-app/page';
import runtime from '@adobe/exc-app';

export const getParentOrigin = () => {
  const locationsAreDistinct = window.location !== window.parent.location;
  const parentOrigin = (
    (locationsAreDistinct ? document.referrer : document.location) || ''
  ).toString();

  if (parentOrigin) {
    return new URL(parentOrigin).origin;
  }

  const currentLocation = document.location;

  // this is not supported in Firefox, so the checks above are required
  if (currentLocation.ancestorOrigins && currentLocation.ancestorOrigins.length) {
    return currentLocation.ancestorOrigins[0];
  }
  return '';
};

/**
 * Checks if running app is running ExC by checking the parent origin and if that fails then falling back to session storage (which checks if the module runtime script is present)
 * @returns boolean
 */
export const getIsInsideExperienceCloud = () => {
  const parentOrigin = getParentOrigin();
  const isExCParentOrigin =
    /(https:\/\/)(exc-unifiedcontent\.)?experience(-qa|-stage)?\.adobe\.(com|net)$/.test(
      parentOrigin
    );
  const checkIfPreviouslyLoadedModuleRuntime =
    window.sessionStorage.getItem('unifiedShellMRScript') !== null;
  return isExCParentOrigin || checkIfPreviouslyLoadedModuleRuntime;
};

export const getIsMaestroStandalone = (): boolean => {
  try {
    const runtimeInstance = runtime();

    if (!runtimeInstance.lastConfigurationPayload) {
      return false;
    }
    return runtimeInstance.lastConfigurationPayload?.appId !== 'workfront';
  } catch (e) {
    return false;
  }
};

export const getIsInGenStudio = (): boolean => {
  try {
    const runtimeInstance = runtime();
    return runtimeInstance.lastConfigurationPayload?.imsClientId === 'genstudio';
  } catch (e) {
    return false;
  }
};

export const generateShellUrl = (type: 'href' | 'path', url: string): string => {
  if (!['path', 'href'].includes(type)) {
    throw new Error(`type argument is required either 'path' or 'href'`);
  }
  if (!url) {
    throw new Error(`url argument is required either relative path or full iframe URL`);
  }

  if (getIsInsideExperienceCloud()) {
    if (type === 'path') {
      return excPage.generateShellUrl({ path: url });
    }
    if (type === 'href') {
      return excPage.generateShellUrl({ href: url });
    }
  }
  return url;
};
