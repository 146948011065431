import { getMaestroPath } from './getMaestroPath';
import { getIsInGenStudio } from './shellHelpers';
import { GenStudioRecordTypeAliases } from './singleSpaApplication';

export const getRecordTypePagePath = (
  workspaceId: string,
  recordTypeId: string,
  viewId: string
) => {
  if (getIsInGenStudio()) {
    const parts = window.location.pathname.split('/').filter(Boolean);
    const recordTypeAlias = parts[0];
    if (GenStudioRecordTypeAliases.has(recordTypeAlias)) {
      return `/${recordTypeAlias}/${workspaceId}/${recordTypeId}/${viewId}/view`;
    }
  }
  return getMaestroPath(`/${workspaceId}/${recordTypeId}/${viewId}/view`);
};
